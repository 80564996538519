
<template>
  <el-row :gutter="20">
    <el-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="12"
      :xl="12"
    >
      <div class="heading">
        <h1 v-if="!moduleId">
          Add Module
        </h1>
        <h1 v-if="moduleId">
          Edit Module
        </h1>
      </div>
    </el-col>
    <el-col
      :xs="6"
      :sm="6"
      :md="6"
      :lg="12"
      :xl="12"
    >
      <div class="topsearch">
        <el-button
          type="primary"
          @click="modules()"
        >
          Modules
        </el-button>
      </div>
    </el-col>
  </el-row>
  <el-row :gutter="20">
    <el-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="24"
      :xl="24"
    >
      <div v-if="showAlertAdded">
        <el-alert
          v-if="!moduleId"
          title="Module Added Successfully"
          type="success"
          effect="dark"
        />
        <el-alert
          v-if="moduleId"
          title="Module Updated Successfully"
          type="success"
          effect="dark"
        />
      </div>
      <div v-if="showAlertSlugexit">
        <el-alert
          title="The slug has already been taken."
          type="error"
          effect="dark"
        />
      </div>
    </el-col>
  </el-row>

  <el-form
    ref="ruleForm"
    :model="data"
    status-icon
    :rules="rules"
    label-width="180px"
    class=" login-form"
    :label-position="labelPosition" 
  >
   
     <el-row :gutter="20">
      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="12"
        :xl="12"
      >
        <el-form-item
          label="Title"
          prop="title"
        >
          <el-input
            v-model="data.title"
            type="text"
            autocomplete="off"
          />
        </el-form-item>
      </el-col>
      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="12"
        :xl="12"
      > 
        <el-form-item
          label="Type"
        >
           <el-select
            v-model="data.type"
          >
            <el-option
              v-for="item in selecttypes" 
              :key="item.label"
              :value="item.value"
              :label="item.label"
            >
              {{ item.label }}
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="24"
        :xl="24"
      >
        <el-form-item
          label="Markup"
          prop="markup"
        >
          <CodeEditor
              :markup="data.markup"
              @codeChanged="codeUpdated"
          ></CodeEditor>
<!--          <el-input-->
<!--            v-model="data.markup"-->
<!--            type="textarea"-->
<!--            :rows="25"-->
<!--            autocomplete="off"-->
<!--          />-->
        </el-form-item>
      </el-col>
    </el-row>
    
    <el-row :gutter="20">
      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="12"
        :xl="12"
      >
        <el-form-item>
          <input
            id="moduleId"
            v-model="moduleId"
            name="moduleId"
            type="hidden"
          >
          <el-button
            type="primary"
            @click="submitForm('ruleForm')"
          >
            Submit
          </el-button>
          <el-button  v-if="!moduleId" @click="resetForm('ruleForm')">
            Reset
          </el-button>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>

import ModulesService from "@/api/modules.service";
import TinyMce from "@/components/TinyMce";
import CodeEditor from "@/components/CodeEditor";

export default {
    components: {
      CodeEditor,
      TinyMce,
    },
    data() {
      var checkName = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the Module Name.'));
        } else {
          callback();
        }
      };
      var checkCode = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the Module Code.'));
        } else {
          callback();
        }
      };
      return {
        showAlertAdded: false,
        showAlertSlugexit: false,
        labelPosition: 'top',
        moduleId: this.$route.params.moduleId,        
		selecttypes:[{
          value: 'module',
          label: 'module'
        }, {
          value: 'cms',
          label: 'cms'
        }],
        data: {
          name: null,
          code:null,
          title:null,
          type:null,
        },
        rules: {
          name: [
            { validator: checkName, trigger: 'blur' }
          ],
          code: [
            { validator: checkCode, trigger: 'blur' }
          ]
        }
      };
    },
	mounted() {
    if(this.moduleId){
      this.getmodule();
    }
  },
  computed: {
       loadEditor: function () {
        return !this.moduleId || (this.moduleId && this.data.description);
        },
  },
    methods: {
      codeUpdated(value) {
        this.data.markup = value
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid && !this.moduleId) {
             return ModulesService.add(this.data).then(response => {
                this.showAlertAdded=true ;
                return response;
              });
          } else if (valid && this.moduleId) {
            return ModulesService.update(this.moduleId, this.data).then(response => {
              this.showAlertAdded=true;
              return response;

            });
          }else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
	modules(){
		this.$router.push("/user/modules");
	},
	getmodule(){
    return ModulesService.get(this.moduleId).then(response => {
        this.data = response.data.data;
        if(response.data.data.image) {
          this.fileList.push({name:'', url: response.data.data.image});
        }
        return response;
      });
	}
    }
  }
</script>
<style>
.el-select-dropdown{
      text-transform: capitalize;
}
.el-select .el-input__inner{
  text-transform: capitalize;
}
</style>
<style lang="scss" scoped>
.el-form--label-top .el-form-item__label{
      float: left !important;
      font-weight: 500;
      font-size: 12px;
      padding: 0 0 0px 0;
}
.el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }  
  .heading {
  text-align: left;
  width: 100%;
  line-height: 2;
}
    .login {
        flex: 1;
		width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
   .login-form {
        width: 100%;
    }
    .selectstatus{
      -webkit-appearance: none;
    background-color: #FFFFFF;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    outline: none;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    }
div .topsearch {
    float: right;
    display: inline-flex;
    padding: 15px 0px;
  }
div .topsearch .el-input__inner{
  padding-right:10px;
  height: 40px;
  width: 500px;
}
div .topsearch .el-input{
  padding-right:10px;
}
.pagination{ 
  padding: 12px 5px;
  float: right;
}
</style>
